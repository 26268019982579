import React from 'react';

import './PgaTermos.css';

function PgaTermos() {

    return (
        <div>


            <h1 style={{ textAlign: 'center' }}>Termos e Condi&ccedil;&otilde;es Gerais de Uso da plataforma &ldquo;ZECA TV&rdquo; (&quot;<strong>TERMOS DE USO</strong>&quot;)</h1>

            <p >Atualizado em 08/06/2023</p>

            <br></br>

            <br></br>

            <p>Este instrumento, daqui em diante chamado &ldquo;<strong>TERMOS DE USO</strong>&rdquo;, serve ao mesmo tempo</p>

            <p>como:</p>

            <br></br>

            <ul style={{ listStyle: 'upper-alpha' }}>
                <li >o Contrato de Licenciamento de Uso de Software entre a <strong>BASCODE DESENVOLVIMENTO DE APLICATIVOS LTDA</strong>., com sede na Rua MARIA CURUPAITI, 19 - Bairro: Vila Ester (Zona Norte), SAO PAULO - SP CEP 02452-000, inscrita no CNPJ sob o n&ordm; 42.377.335/0001-73 e/ou suas controladoras, controladas, coligadas (doravante denominada &quot;<strong>BASCODE</strong>&quot;); e seus respectivos <strong>ARTISTAS</strong> E <strong>P&Uacute;BLICO</strong>.</li>

                <li >o estabelecimento de condi&ccedil;&otilde;es gerais pelas quais os <strong>ARTISTAS</strong> e o <strong>P&Uacute;BLICO</strong> fiquem cientes a que se submetem ante &agrave; plataforma &ldquo;<strong>ZECA TV</strong>&rdquo; (tamb&eacute;m denominada &ldquo;<strong>PLATAFORMA</strong>&rdquo;);</li>

                <li >o Instrumento consensual, de car&aacute;ter plenamente vinculante entre os <strong>ARTISTAS</strong> e o <strong>P&Uacute;BLICO</strong> para com a <strong>BASCODE</strong>;</li>

                <li >a limita&ccedil;&atilde;o das responsabilidades da <strong>BASCODE</strong> no concernente a eventuais falhas decorrentes de conduta de outrem.</li>
            </ul>

            <br></br>

            <br></br>

            <ol class="list-root" >
                <li ><strong>DIRETRIZES GERAIS:</strong>

                    <ol  >
                        <li >A aceita&ccedil;&atilde;o dos <strong>TERMOS DE USO</strong> &eacute; obrigat&oacute;ria para a utiliza&ccedil;&atilde;o dos SERVI&Ccedil;OS prestados pela <strong>BASCODE</strong> e a mera utiliza&ccedil;&atilde;o da <strong>PLATAFORMA</strong> implica a imediata concord&acirc;ncia com todas as suas cl&aacute;usulas, pol&iacute;ticas e princ&iacute;pios.</li>
                        <li >Os <strong>TERMOS DE USO</strong> s&atilde;o mostrados aos <strong>ARTISTAS</strong> e ao <strong>P&Uacute;BLICO</strong>, e por eles necessariamente aceitos quando do in&iacute;cio do processo de instala&ccedil;&atilde;o, cadastro e utiliza&ccedil;&atilde;o da <strong>PLATAFORMA</strong> e, dada sua publicidade, n&atilde;o h&aacute; que se alegar desconhecimento das regras e obriga&ccedil;&otilde;es criadas por meio deles.</li>
                        <li >Toda e qualquer pessoa, f&iacute;sica ou jur&iacute;dica, que se cadastrar nesta <strong>PLATAFORMA</strong> declara aceitar estes <strong>TERMOS DE USO</strong>.</li>
                        <li >No concernente aos USU&Aacute;RIOS (sejam <strong>ARTISTAS</strong> ou <strong>P&Uacute;BLICO</strong>), os <strong>TERMOS DE USO</strong> constituem um acordo entre os USU&Aacute;RIOS e a <strong>BASCODE</strong>, governando o uso da <strong>PLATAFORMA</strong>. Uma vez utilizando a <strong>PLATAFORMA</strong>, os USU&Aacute;RIOS anuem que est&atilde;o em conson&acirc;ncia com as estipula&ccedil;&otilde;es presentes nestes termos e condi&ccedil;&otilde;es de uso, considerando-se sempre a vers&atilde;o publicada &agrave; data do acesso.</li>
                        <li >O presente TERMO DE USO estabelece os limites da responsabilidade da <strong>BASCODE</strong>.</li>
                        <li >Ao usar a <strong>PLATAFORMA</strong>, os USU&Aacute;RIOS (sejam <strong>ARTISTAS</strong> ou <strong>P&Uacute;BLICO</strong>) assumem determinadas responsabilidades, em conformidade com a legisla&ccedil;&atilde;o vigente.</li>
                        <li >O <strong>P&Uacute;BLICO</strong> deve se atentar para os meios de pagamento quando da aquisi&ccedil;&atilde;o do produto e/ou servi&ccedil;o; ao passo que os <strong>ARTISTAS</strong> devem atentar-se &agrave;s pol&iacute;ticas de remunera&ccedil;&atilde;o da <strong>PLATAFORMA</strong>.</li>
                    </ol>
                </li>

                <li ><strong>DEFINI&Ccedil;&Otilde;ES:</strong>

                    <ol>
                        <li ><strong>PLATAFORMA</strong>: consiste em todas as esferas existentes no ambiente digital da <strong>BASCODE</strong>, sendo constitu&iacute;da por Application Interface Program (&ldquo;API&rdquo;); Sistema Administrativo; WebAPP (tanto para computadores, como para tablets e smartphones); blogs; aplicativos, em especial o <strong>ZECA TV</strong>, e toda a engenharia da&iacute; decorrente.</li>
                        <li ><strong>BASCODE</strong>: &eacute; empresa propriet&aacute;ria e licenciante da <strong>PLATAFORMA</strong>, sendo um ecossistema criado para conectar <strong>ARTISTAS</strong> e <strong>P&Uacute;BLICO</strong> no &acirc;mbito da m&uacute;sica, shows, produ&ccedil;&atilde;o cultural e quaisquer outras atividades correlatas.</li>
                        <li >USU&Aacute;RIO: trata-se de uma universalidade composta pelo <strong>P&Uacute;BLICO</strong> (destinat&aacute;rio final do espet&aacute;culo) e pelos <strong>ARTISTAS</strong> (quem performa o espet&aacute;culo).</li>
                        <li ><strong>ZECA TV</strong>: consiste em todos elementos que englobam a <strong>PLATAFORMA</strong> da sociedade empres&aacute;ria.</li>
                        <li >LIVE: espet&aacute;culo ao vivo performado pelo ARTISTA destinado ao <strong>P&Uacute;BLICO</strong>.</li>
                        <li >CHARGEBACK: &eacute; o procedimento por meio do qual o USU&Aacute;RIO contata a operadora de seu cart&atilde;o de cr&eacute;dito para contestar uma transa&ccedil;&atilde;o realizada, alegando n&atilde;o ter autorizado o pagamento do servi&ccedil;o.</li>
                        <li >PARCEIRO FINANCEIRO: &eacute; o intermediador (&ldquo;gestora de pagamento&rdquo;) que possui autoriza&ccedil;&atilde;o para processar o pagamento do produto, bem como possui autoriza&ccedil;&atilde;o, emitida pelo ente federativo correspondente, para armazenar os dados das transa&ccedil;&otilde;es financeiras.</li>
                    </ol>
                </li>

                <li><strong>APRESENTA&Ccedil;&Atilde;O DO ZECA TV:</strong>

                    <ol>
                        <li>A <strong>BASCODE</strong> desenvolveu uma plataforma para disponibiliza&ccedil;&atilde;o, visualiza&ccedil;&atilde;o e gerenciamento de LIVES onde <strong>ARTISTAS</strong> podem performar shows online para o <strong>P&Uacute;BLICO</strong>. Os <strong>ARTISTAS</strong> podem interagir com o <strong>P&Uacute;BLICO</strong> atrav&eacute;s de um chat. Essas LIVES podem ser free ou pagas, sendo a primeira acess&iacute;vel ao <strong>P&Uacute;BLICO</strong> mediante o pagamento de um valor enquanto a segunda &eacute; livre ao <strong>P&Uacute;BLICO</strong>. Cabem aos <strong>ARTISTAS</strong> definirem se as LIVES ser&atilde;o free ou pagas bem como o valor cobrado. Tal plataforma engloba tamb&eacute;m qualquer outro servi&ccedil;o oferecido ou disponibilizado no <strong>ZECA TV</strong> ou por interm&eacute;dio de qualquer plataforma, interface ou dispositivo do <strong>ZECA TV</strong> e do seu correspectivo Grupo Econ&ocirc;mico (&quot;SERVI&Ccedil;OS&quot;).</li>
                        <li>Ao acessar o <strong>ZECA TV</strong> e/ou utilizar os SERVI&Ccedil;OS, voc&ecirc; (&quot;USU&Aacute;RIO&quot;) reconhece e aceita que os termos e condi&ccedil;&otilde;es abaixo aplicar-se-&atilde;o juntamente com a POL&Iacute;TICA DE PRIVACIDADE (&quot;POL&Iacute;TICA DE PRIVACIDADE&quot;) e demais documentos periodicamente atualizados e publicados no <strong>ZECA TV</strong> e que integrar&atilde;o os <strong>TERMOS DE USO</strong> por refer&ecirc;ncia.</li>
                        <li>Para acessar e usar o <strong>ZECA TV</strong> e/ou os SERVI&Ccedil;OS, o USU&Aacute;RIO dever&aacute; ter mais de 12 (doze) anos de idade. O USU&Aacute;RIO menor de 12 (doze) anos apenas poder&aacute; acessar e usar o <strong>ZECA TV</strong> mediante autoriza&ccedil;&atilde;o espec&iacute;fica de pelo menos um dos pais ou do respons&aacute;vel legal. O <strong>ZECA TV</strong> reserva-se o direito de solicitar prova de idade de qualquer USU&Aacute;RIO, a qualquer momento, para verificar se o acesso e uso est&atilde;o de acordo com estes <strong>TERMOS DE USO</strong> e com a POL&Iacute;TICA DE PRIVACIDADE, bem como de suspender e/ou cancelar quaisquer contas que assim n&atilde;o estejam.</li>
                    </ol>
                </li>

                <li><strong>DOS SERVI&Ccedil;OS DO ZECA TV:</strong>

                    <ol>
                        <li>Os SERVI&Ccedil;OS consistem em ofertar e disponibilizar aos USU&Aacute;RIOS:

                            <ol>
                                <li>Acesso e uso do <strong>ZECA TV</strong> para que o <strong>P&Uacute;BLICO</strong> possa visualizar LIVES de <strong>ARTISTAS</strong>.</li>
                                <li>Busca das LIVES por <strong>ARTISTAS</strong> ou por ESTILO MUSICAL.</li>
                                <li>Intera&ccedil;&atilde;o do <strong>P&Uacute;BLICO</strong> com os <strong>ARTISTAS</strong> atrav&eacute;s de um chat.</li>
                                <li>Pagamento das LIVES pelo <strong>P&Uacute;BLICO</strong> aos <strong>ARTISTAS</strong> caso seja uma LIVE paga ou pagamento caso o <strong>P&Uacute;BLICO</strong> decida prestigiar os <strong>ARTISTAS</strong> ao longo de uma LIVE free.</li>
                            </ol>
                        </li>
                        <li>O <strong>ZECA TV</strong> poder&aacute; fornecer atualiza&ccedil;&otilde;es (ainda que autom&aacute;ticas) para determinados SERVI&Ccedil;OS como e quando achar adequado. Isso pode incluir atualiza&ccedil;&otilde;es, modifica&ccedil;&otilde;es, corre&ccedil;&otilde;es de bugs e realiza&ccedil;&atilde;o de fixes de outros erros e/ou novos recursos (coletivamente, &quot;Atualiza&ccedil;&otilde;es&quot;). Determinadas partes dos SERVI&Ccedil;OS podem n&atilde;o funcionar corretamente se o USU&Aacute;RIO n&atilde;o instalar todas as Atualiza&ccedil;&otilde;es. O USU&Aacute;RIO concorda expressamente com Atualiza&ccedil;&otilde;es autom&aacute;ticas e que estes <strong>TERMOS DE USO</strong> se aplicar&atilde;o a todas as Atualiza&ccedil;&otilde;es.</li>
                        <li>As atualiza&ccedil;&otilde;es peri&oacute;dicas ser&atilde;o informadas nas plataformas &ldquo;App Store&rdquo; e &ldquo;Google Play&rdquo;, contendo memorial descritivo de &ldquo;bugs&rdquo; e &ldquo;fixes&rdquo;.</li>
                        <li>Em caso de altera&ccedil;&atilde;o destes <strong>TERMOS DE USO</strong> e/ou da POL&Iacute;TICA DE PRIVACIDADE, o <strong>ZECA TV</strong> compromete-se a enviar a informar as mudan&ccedil;as ocorridas na plataforma quando do primeiro acesso do USU&Aacute;RIO, contendo um resumo das supramencionadas altera&ccedil;&otilde;es.</li>
                        <li>Os <strong>ARTISTAS</strong> podem disponibilizar LIVES free ou pagas. Nas LIVES free, o <strong>P&Uacute;BLICO</strong> paga aos <strong>ARTISTAS</strong> o valor do ingresso estipulado pelos mesmos para poder ter acesso &agrave; LIVE. Nas LIVES pagas, o <strong>P&Uacute;BLICO</strong> pode acessar gratuitamente &agrave; LIVE, sendo facultado prestigiar os <strong>ARTISTAS</strong> com um valor de sua prefer&ecirc;ncia no decorrer da LIVE. O valor m&iacute;nimo em ambos &eacute; de R$10,00 (dez reais), com incrementos de R$10,00 (dez reais).
                            <ol>
                                <li>A remunera&ccedil;&atilde;o da plataforma &eacute; feita de duas formas: comiss&atilde;o do valor pago pelo <strong>P&Uacute;BLICO</strong> a t&iacute;tulo de ingresso na LIVE free; e comiss&atilde;o do valor pago pelo <strong>P&Uacute;BLICO</strong> a t&iacute;tulo de contribui&ccedil;&atilde;o na LIVE paga. O percentual de comiss&atilde;o em ambos os casos &eacute; de 15% (quinze por cento), sendo certo que os custos de transa&ccedil;&atilde;o (custo do cart&atilde;o de cr&eacute;dito e da transfer&ecirc;ncia banc&aacute;ria) ser&atilde;o absorvidos pela <strong>PLATAFORMA</strong>.</li>
                                <li>No valor da comiss&atilde;o paga ao <strong>ZECA TV</strong>, o ARTISTA poder&aacute; entrar em contato com a equipe t&eacute;cnica do APP por meio do e-mail contato@zeca.tv para ajudar na grava&ccedil;&atilde;o da live e no fornecimento de artes de divulga&ccedil;&atilde;o para as lives.</li>
                            </ol>
                        </li>
                        <li>Qualquer acerto entre os <strong>ARTISTAS</strong> e o <strong>P&Uacute;BLICO</strong> fora da plataforma <strong>ZECA TV</strong> dar&aacute; azo a exclus&atilde;o imediata destes, se &agrave; <strong>ZECA TV</strong> lhe aprouver.</li>
                        <li>O USU&Aacute;RIO reconhece e aceita que o <strong>ZECA TV</strong> n&atilde;o se envolve na negocia&ccedil;&atilde;o das LIVES entre os <strong>ARTISTAS</strong> e o <strong>P&Uacute;BLICO</strong>, facilitando apenas o contato entre eles.</li>
                        <li>Os <strong>ARTISTAS</strong> responder&atilde;o isoladamente por suas pr&oacute;prias obriga&ccedil;&otilde;es tribut&aacute;rias, trabalhistas e previdenci&aacute;rias, ou outras pertinentes &agrave; presta&ccedil;&atilde;o dos seus SERVI&Ccedil;OS e ser&aacute; respons&aacute;vel pelos atos, danos ou indeniza&ccedil;&otilde;es a que der causa, dolosa ou culposamente, inclusive em rela&ccedil;&atilde;o &agrave;s reclama&ccedil;&otilde;es de terceiros, seja a que t&iacute;tulo for.</li>
                    </ol>
                </li>

                <li><strong>DO ACESSO E CADASTRO DE USU&Aacute;RIOS:</strong>

                    <ol>
                        <li>Para acessar o <strong>ZECA TV</strong>, ser&aacute; necess&aacute;rio realizar um cadastro. H&aacute; duas formas de se realizar esse cadastro:
                            <div style={{ margin: '1em' }}>
                                <p>Login e senha, sendo o login o endere&ccedil;o de e-mail do USU&Aacute;RIO;</p>
                                <p>E-mail e senha da rede social FACEBOOK do USU&Aacute;RIO.</p>
                            </div>
                        </li>

                        <li>O USU&Aacute;RIO que optar por cadastrar-se por interm&eacute;dio do <strong>ZECA TV</strong>, dever&aacute; fornecer as informa&ccedil;&otilde;es de acordo com a Cl&aacute;usula n&ordm; 2, da POL&Iacute;TICA DE PRIVACIDADE, bem como na Cl&aacute;usula 5.6. destes <strong>TERMOS DE USO</strong>.</li>
                        <li>O acesso e uso do <strong>ZECA TV</strong> s&atilde;o gratuitos (inclusive o cadastro), podendo o <strong>ZECA TV</strong> alterar a qualquer tempo, e a seu exclusivo crit&eacute;rio, a sua pol&iacute;tica de acesso e uso, passando a cobrar por sua utiliza&ccedil;&atilde;o, sendo que as altera&ccedil;&otilde;es passar&atilde;o a vigorar 30 (trinta) dias ap&oacute;s a divulga&ccedil;&atilde;o por escrito (via checkbox) aos USU&Aacute;RIOS.</li>
                        <li>Os <strong>ARTISTAS</strong> ou <strong>P&Uacute;BLICO</strong> com cadastro poder&atilde;o visualizar as LIVES existentes sejam free ou pagas.</li>
                        <li>Para poder utilizar todos os SERVI&Ccedil;OS do <strong>ZECA TV</strong>, os USU&Aacute;RIOS dever&atilde;o criar um cadastro.
                            <ol>
                                <li>Caso o USU&Aacute;RIO seja <strong>P&Uacute;BLICO</strong>, dever&aacute; fornecer as seguintes informa&ccedil;&otilde;es:
                                    <div style={{ margin: '1em' }}>

                                        <p>Foto de perfil;</p>
                                        <p>Nome;</p>
                                        <p>E-mail;</p>
                                        <p>Descri&ccedil;&atilde;o do perfil;</p>
                                        <p>Estilo musical.</p>


                                    </div>


                                </li>
                                <li>Caso o USU&Aacute;RIO seja ARTISTA, dever&aacute; fornecer as seguintes informa&ccedil;&otilde;es:
                                    <div style={{ margin: '1em' }}>
                                        <p>Foto de perfil;</p>
                                        <p>Nome do artista ou banda;</p>
                                        <p>E-mail;</p>
                                        <p>Descri&ccedil;&atilde;o da banda ou artista;</p>
                                        <p>Estilo musical;</p>
                                        <p>CPF;</p>
                                        <p>T&iacute;tulo da live que o ARTISTA ir&aacute; realizar;</p>
                                        <p>Escolha pelo ARTISTA se a live ser&aacute; free ou paga;</p>
                                        <p>Data da pr&oacute;xima live.</p>
                                    </div>
                                </li>
                            </ol>
                        </li>
                        <li>O USU&Aacute;RIO ser&aacute; &uacute;nica e exclusivamente respons&aacute;vel pelas informa&ccedil;&otilde;es fornecidas para seu cadastro, bem como por manter o sigilo e a confidencialidade de seus dados e de sua senha de acesso perante terceiros. O USU&Aacute;RIO reconhece e aceita que lhe fica especialmente vedado:
                            <ol>
                                <li>fornecer qualquer informa&ccedil;&atilde;o que n&atilde;o seja verdadeira, completa, precisa e atualizada (especialmente no tocante &agrave; dados pessoais e banc&aacute;rios, quando pertinentes);</li>
                                <li>efetuar registro em nome de terceiros, ou utilizar-se de nome ou informa&ccedil;&otilde;es de terceiros, sem autoriza&ccedil;&atilde;o pr&eacute;via e escrita destes;</li>
                                <li>acessar e/ou divulgar de forma n&atilde;o autorizada informa&ccedil;&otilde;es do <strong>ZECA TV</strong>, dos demais USU&Aacute;RIOS ou de terceiros ou de qualquer forma desrespeitar a intimidade e/ou privacidade de qualquer pessoa;</li>
                            </ol>
                        </li>
                        <li>O <strong>ZECA TV</strong> poder&aacute; rejeitar ou cancelar o cadastro de qualquer USU&Aacute;RIO que, a seu exclusivo crit&eacute;rio, deixe de atender o disposto nestes <strong>TERMOS DE USO</strong>, ou possa estar fornecendo informa&ccedil;&otilde;es falsas, incompletas ou imprecisas. N&atilde;o ser&aacute; permitida a cria&ccedil;&atilde;o de novos cadastros por USU&Aacute;RIO que tenha tido seu cadastro cancelado por viola&ccedil;&atilde;o destes <strong>TERMOS DE USO</strong>.</li>
                        <li>O <strong>ZECA TV</strong> obriga-se a tratar como confidenciais os dados pessoais do USU&Aacute;RIO que permitam a sua identifica&ccedil;&atilde;o, de acordo com sua POL&Iacute;TICA DE PRIVACIDADE. Todavia, o USU&Aacute;RIO reconhece e aceita que o <strong>ZECA TV</strong> n&atilde;o tem como assegurar o sigilo e a confidencialidade de toda e qualquer informa&ccedil;&atilde;o fornecida e/ou divulgada pelo USU&Aacute;RIO em ambiente virtual, principalmente &agrave;quela veiculada por interm&eacute;dio do <strong>ZECA TV</strong>, ou de qualquer outra forma (tais como envio de d&uacute;vidas, sugest&otilde;es e/ou coment&aacute;rios).</li>
                        <li>O USU&Aacute;RIO reconhece que o ambiente virtual n&atilde;o &eacute; desprovido de c&oacute;digos maliciosos, malwares, v&iacute;rus, reconhecendo, pois, o <strong>ZECA TV</strong> n&atilde;o tem como assegurar o sigilo e a confidencialidade de toda e qualquer informa&ccedil;&atilde;o cadastral; devendo, todavia, envidar os seus maiores esfor&ccedil;os no combate a hackers e afins.</li>
                        <li>O <strong>ZECA TV</strong> poder&aacute; suspender o acesso e/ou cancelar a utiliza&ccedil;&atilde;o do <strong>ZECA TV</strong> ou de qualquer dos SERVI&Ccedil;OS, a qualquer tempo e independentemente de qualquer motivo ou raz&atilde;o, mediante notifica&ccedil;&atilde;o escrita ao USU&Aacute;RIO com 48 (quarenta e oito horas) horas de anteced&ecirc;ncia. Sem preju&iacute;zo de outras medidas cab&iacute;veis, o <strong>ZECA TV</strong> poder&aacute; suspender o acesso e cancelar definitivamente a conta de um USU&Aacute;RIO, a qualquer tempo, mediante comunica&ccedil;&atilde;o escrita com efeito imediato se:
                            <ol>
                                <li>O <strong>ZECA TV</strong> receber qualquer ordem judicial solicitando o cancelamento da conta do USU&Aacute;RIO;</li>
                                <li>O <strong>ZECA TV</strong> receber comunica&ccedil;&atilde;o de outro USU&Aacute;RIO ou de terceiros reportando o uso indevido pelo USU&Aacute;RIO de informa&ccedil;&otilde;es (especialmente dados pessoais) de qualquer outro USU&Aacute;RIO ou de terceiros;</li>
                                <li>O USU&Aacute;RIO deixar de cumprir qualquer disposi&ccedil;&atilde;o destes <strong>TERMOS DE USO</strong> e demais pol&iacute;ticas do <strong>ZECA TV</strong>, especialmente a POL&Iacute;TICA DE PRIVACIDADE.</li>
                            </ol>
                        </li>
                        <li>O USU&Aacute;RIO poder&aacute; igualmente cancelar seu cadastro e, consequentemente, o acesso e a utiliza&ccedil;&atilde;o do <strong>ZECA TV</strong> e/ou de qualquer dos SERVI&Ccedil;OS, a qualquer tempo e independentemente de qualquer motivo ou raz&atilde;o, seguindo as instru&ccedil;&otilde;es indicadas no <strong>ZECA TV</strong>. Fica desde j&aacute; estabelecido que, em qualquer hip&oacute;tese de cancelamento da utiliza&ccedil;&atilde;o do <strong>ZECA TV</strong> ou de qualquer dos SERVI&Ccedil;OS, o USU&Aacute;RIO n&atilde;o ter&aacute; direito a qualquer reembolso ou restitui&ccedil;&atilde;o de quantias eventualmente pagas, ficando desde j&aacute; estabelecido que as disposi&ccedil;&otilde;es destes <strong>TERMOS DE USO</strong> que, por sua pr&oacute;pria natureza, tenham car&aacute;ter perene, sobreviver&atilde;o ao cancelamento do acesso e do uso do <strong>ZECA TV</strong> e dos SERVI&Ccedil;OS.
                            <ol>
                                <li>O USU&Aacute;RIO fica desde j&aacute; ciente que os seus dados ficar&atilde;o na Application Programming Interface (API) e no banco de dados da <strong>ZECA TV</strong>, a fim de facilitar o processo de recadastro do USU&Aacute;RIO na <strong>PLATAFORMA</strong>.</li>
                                <li>Acaso o <strong>USU&Aacute;RIO</strong> deseje a exclus&atilde;o definitiva de todos os seus dados da <strong>PLATAFORMA</strong>, este dever&aacute; entrar em contato com o endere&ccedil;o contato@zeca.tv, requerendo seu cancelamento definitivo, o que ocorrer&aacute; em at&eacute; 72 (setenta e duas) horas subsequentes.</li>
                            </ol>
                        </li>
                    </ol>
                </li>

                <li><strong>DO PAGAMENTO:</strong>

                    <ol>
                        <li>O pagamento do <strong>P&Uacute;BLICO</strong> ao <strong>ARTISTA</strong>, processar-se-&aacute; por interm&eacute;dio do <strong>ZECA TV</strong>, cujas transa&ccedil;&otilde;es s&atilde;o processadas e armazenadas junto a seu PARCEIRO FINANCEIRO.</li>
                        <li>Para o recebimento dos pagamentos, o perfil de ARTISTA dever&aacute; conter as seguintes informa&ccedil;&otilde;es atualizadas: (i) nome do titular; (ii) CPF do titular; (iii) nome do banco; (iv) tipo de conta (se conta corrente ou conta poupan&ccedil;a); (v) ag&ecirc;ncia banc&aacute;ria; e (vi) n&uacute;mero da conta.
                            <ol>
                                <li>Sempre que o USU&Aacute;RIO cadastrar suas informa&ccedil;&otilde;es banc&aacute;rias pela primeira vez, tais informa&ccedil;&otilde;es dever&atilde;o ser validadas pelo <strong>ZECA TV</strong>. Tal processo pode demorar at&eacute; 1 (hum) dia &uacute;til para ser realizado. Na hip&oacute;tese de alterar as informa&ccedil;&otilde;es banc&aacute;rias, &eacute; necess&aacute;rio que se passe pelo mesmo processo de valida&ccedil;&atilde;o novamente.</li>
                            </ol>
                        </li>
                        <li>Para a realiza&ccedil;&atilde;o dos pagamentos, o perfil de <strong>P&Uacute;BLICO</strong> dever&aacute; preencher as seguintes informa&ccedil;&otilde;es: (i) valor a ser pago (no caso apenas da modalidade live free, sendo certo que o valor &eacute; a partir de R$10,00 (dez reais), com acr&eacute;scimos de R$10,00 (dez reais)); (ii) n&uacute;mero do cart&atilde;o de cr&eacute;dito; (ii) data de expira&ccedil;&atilde;o do cart&atilde;o de cr&eacute;dito;(iii) c&oacute;digo de seguran&ccedil;a do cart&atilde;o de cr&eacute;dito; (iv) nome do titular do cart&atilde;o de cr&eacute;dito; e (v) CPF do titular do cart&atilde;o de cr&eacute;dito.</li>
                        <li>Na se&ccedil;&atilde;o CONFIGURAR CONTA, o ARTISTA poder&aacute; consultar o seu EXTRATO FINANCEIRO, onde encontrar&aacute; as seguintes informa&ccedil;&otilde;es:
                            <ul >
                                <li>Saldo atual;</li>
                                <li>Lista com o nome dos USU&Aacute;RIOS que prestigiaram na live free ou pagaram ingresso na live paga, valor do pagamento e data (dia e m&ecirc;s) do pagamento;</li>
                                <li>Ap&oacute;s clicar no bot&atilde;o RECEBER, saldo atual, taxa de 15% referente &agrave; comiss&atilde;o do <strong>ZECA TV</strong> e saldo a receber; e dados da conta cadastrada.</li>
                            </ul>
                        </li>
                        <li>O pagamento do USU&Aacute;RIO ser&aacute; realizado somente por cart&atilde;o de cr&eacute;dito.</li>
                        <li>Do pagamento ao ARTISTA advir&aacute; a comiss&atilde;o cobrada pela <strong>BASCODE</strong>. Tal comiss&atilde;o ser&aacute; de 15% (quinze por cento), sendo que os custos de transa&ccedil;&otilde;es (custos do cart&atilde;o de cr&eacute;dito e de transfer&ecirc;ncia banc&aacute;ria) ser&atilde;o absorvidos pela <strong>BASCODE</strong>.</li>
                        <li>Os recibos das transa&ccedil;&otilde;es financeiras ser&atilde;o enviadas por e-mail ao <strong>P&Uacute;BLICO</strong>.</li>
                        <li>O pagamento e armazenamento da transa&ccedil;&atilde;o se promover&aacute; por meio das empresas respons&aacute;veis pela gest&atilde;o de pagamentos, subcontratadas pela <strong>BASCODE</strong> para esta finalidade.
                            <ol>
                                <li>O pagamento mediante cart&atilde;o de cr&eacute;dito ser&aacute; disponibilizado em at&eacute; 1 (dia) &uacute;til a contar da data de sua efetiva&ccedil;&atilde;o.</li>
                            </ol>
                        </li>
                        <li>A <strong>BASCODE</strong> n&atilde;o poder&aacute; intervir, nem ser&aacute; respons&aacute;vel pelos resultados da rela&ccedil;&atilde;o do USU&Aacute;RIO com as empresas respons&aacute;veis pela gest&atilde;o de pagamentos, uma vez que as mesmas administram suas opera&ccedil;&otilde;es de forma independente.</li>
                        <li>A <strong>BASCODE</strong> n&atilde;o pode garantir de forma nenhuma que os SERVI&Ccedil;OS prestados pelas empresas respons&aacute;veis pela gest&atilde;o de pagamentos funcionar&atilde;o livres de erros, interrup&ccedil;&otilde;es, mal funcionamento, atrasos ou outras imperfei&ccedil;&otilde;es.</li>
                        <li>A <strong>BASCODE</strong> n&atilde;o ser&aacute; respons&aacute;vel pela disponibilidade ou n&atilde;o dos SERVI&Ccedil;OS prestados pelas empresas respons&aacute;veis pela gest&atilde;o de pagamentos, ou ainda pela impossibilidade do uso do servi&ccedil;o.</li>
                        <li>Em que pese o disposto acima, a <strong>BASCODE</strong> se reserva ao direito de reembolsar o <strong>P&Uacute;BLICO</strong> no caso dos <strong>ARTISTAS</strong> n&atilde;o comparecerem &agrave; LIVE. Para isso, o <strong>P&Uacute;BLICO</strong> dever&aacute; em contato com o suporte do <strong>ZECA TV</strong> no e-mail contato@zeca.tv.</li>
                        <li>Todos estes casos ser&atilde;o avaliados e as decis&otilde;es administrativas da <strong>BASCODE</strong> ser&atilde;o comunicadas ao <strong>P&Uacute;BLICO</strong> via e-mail informativo, avisando acerca do estorno, ou n&atilde;o, no valor do pagamento efetuado quando da aquisi&ccedil;&atilde;o, respeitando-se, sempre, as regras contidas no presente TERMO DE USO.</li>
                        <li>A <strong>BASCODE</strong> ter&aacute; o prazo de 5 (cinco) dias corridos ap&oacute;s a solicita&ccedil;&atilde;o de estorno para averiguar o ocorrido, ressarcindo, se assim decidido, em at&eacute; 10 (dez) dias &uacute;teis, a contar da comunica&ccedil;&atilde;o da an&aacute;lise de estorno.</li>
                    </ol>
                </li>

                <li><strong>DAS FRAUDES E DO CHARGEBACK:</strong>

                    <ol>
                        <li>A <strong>BASCODE</strong> n&atilde;o se responsabiliza por quaisquer condutas fraudulentas por parte de USU&Aacute;RIO, podendo inclusive impedir, em raz&atilde;o delas, seu acesso &agrave; <strong>PLATAFORMA</strong>.

                            <ol>
                                <li>A <strong>BASCODE</strong> poder&aacute;, ainda, agir judicial ou extrajudicialmente contra USU&Aacute;RIO envolvido em fraude.</li>
                                <li>A <strong>BASCODE</strong> analisa caso a caso as suspeitas de fraude e as ocorr&ecirc;ncias de CHARGEBACK. Durante a verifica&ccedil;&atilde;o de potencial fraude ou CHARGEBACK, a <strong>BASCODE</strong> pode reter o pagamento de valores, de forma a evitar o risco de dano ou preju&iacute;zo econ&ocirc;mico pr&oacute;prio, dos <strong>ARTISTAS</strong> ou de qualquer outro USU&Aacute;RIO, a depender do caso, sem preju&iacute;zo da obriga&ccedil;&atilde;o de indenizar por eventuais danos causados nos termos da legisla&ccedil;&atilde;o em vigor.</li>
                            </ol>
                        </li>
                        <li>A <strong>BASCODE</strong> n&atilde;o possui qualquer ger&ecirc;ncia sobre os CHARGEBACKS, leg&iacute;timos ou n&atilde;o, realizados por um USU&Aacute;RIO.</li>
                        <li>A <strong>BASCODE</strong> n&atilde;o se responsabiliza pelos CHARGEBACKS eventualmente realizados pelo USU&Aacute;RIO, nem efetua pagamento ao USU&Aacute;RIO correspondente a valores perdidos em fun&ccedil;&atilde;o dos referidos CHARGEBACKS.
                            <ol>
                                <li>Em todos os casos de CHARGEBACK, contudo, a <strong>BASCODE</strong> envidar&aacute; seus melhores esfor&ccedil;os para resolu&ccedil;&atilde;o do problema, de forma a evitar a contesta&ccedil;&atilde;o indevida da fatura pelo USU&Aacute;RIO, conforme for o caso.</li>
                            </ol>
                        </li>
                    </ol>
                </li>

                <li><strong>DO CONTE&Uacute;DO DO USU&Aacute;RIO:</strong>

                    <ol>
                        <li>O USU&Aacute;RIO ser&aacute; o &uacute;nico e exclusivo respons&aacute;vel por todo e qualquer conte&uacute;do, material ou informa&ccedil;&atilde;o (inclusive dados pessoais, e/ou de terceiros) que venha a fornecer, inserir, divulgar, publicar ou transmitir diretamente para acesso, cadastro e/ou uso do <strong>ZECA TV</strong> ou dos SERVI&Ccedil;OS (&quot;CONTE&Uacute;DO DO USU&Aacute;RIO&quot;), respondendo integralmente pela veracidade, exatid&atilde;o, atualiza&ccedil;&atilde;o e legalidade do CONTE&Uacute;DO DO USU&Aacute;RIO. Todo e qualquer CONTE&Uacute;DO DO USU&Aacute;RIO fornecido, inserido ou de qualquer forma divulgado no <strong>ZECA TV</strong> ou por interm&eacute;dio dos SERVI&Ccedil;OS &eacute; de responsabilidade exclusiva do USU&Aacute;RIO e o <strong>ZECA TV</strong> n&atilde;o poder&aacute; ser responsabilizado por qualquer falsidade, imprecis&atilde;o, incorre&ccedil;&atilde;o ou omiss&atilde;o no CONTE&Uacute;DO DO USU&Aacute;RIO.</li>
                        <li>Quando o USU&Aacute;RIO publica o &ldquo;CONTE&Uacute;DO DO USU&Aacute;RIO&rdquo; por meio de certos recursos interativos ou da comunidade, ele pode se tornar <strong>p&uacute;blico</strong>. Como esses recursos costumam ser <strong>p&uacute;blicos</strong>, o USU&Aacute;RIO entende que o &ldquo;CONTE&Uacute;DO DO USU&Aacute;RIO&rdquo; pode e deve permanecer <strong>p&uacute;blico</strong>. Dessa forma, o USU&Aacute;RIO nunca deve postar nenhuma informa&ccedil;&atilde;o pessoal em nenhuma &aacute;rea interativa ou comunit&aacute;ria. O USU&Aacute;RIO &eacute; totalmente respons&aacute;vel por todas as atividades que ocorram em sua conta, e concorda em notificar o <strong>ZECA TV</strong> imediatamente, atrav&eacute;s de meio seguro, no caso de qualquer acesso ou uso n&atilde;o autorizado de sua conta ou ainda qualquer outra viola&ccedil;&atilde;o de seguran&ccedil;a.</li>
                        <li>A <strong>BASCODE</strong> se compromete a jamais alienar a sua base de dados para terceiros, em estrita observ&acirc;ncia &agrave; Lei n&ordm; 13.709/2018 &ndash; Lei Geral de Prote&ccedil;&atilde;o de Dados (LGPD); ressalvadas as hip&oacute;teses de fus&atilde;o, aquisi&ccedil;&atilde;o, incorpora&ccedil;&atilde;o, venda do controle acion&aacute;rio e afins.
                            <ol>
                                <li>A Cl&aacute;usula n&ordm; 8.3. n&atilde;o se aplica &agrave;s hip&oacute;teses de subcontrata&ccedil;&atilde;o de empresas terceiras, de prestadores de servi&ccedil;o de car&aacute;ter eventual ou n&atilde;o habitual, com o escopo de fomentar as estrat&eacute;gias das &aacute;reas de marketing e comercial, &agrave; benesse da <strong>BASCODE</strong> e para a empresa que comprar os direitos do <strong>ZECA TV</strong> na hip&oacute;tese de venda da <strong>PLATAFORMA</strong>.</li>
                            </ol>
                        </li>
                        <li>O USU&Aacute;RIO reconhece e aceita que, ao fornecer, inserir, divulgar, publicar ou transmitir qualquer CONTE&Uacute;DO DO USU&Aacute;RIO diretamente para acesso, cadastro e/ou uso do <strong>ZECA TV</strong> ou dos SERVI&Ccedil;OS, o USU&Aacute;RIO est&aacute; concedendo ao <strong>ZECA TV</strong>, em car&aacute;ter gratuito, n&atilde;o exclusivo, transfer&iacute;vel e em n&iacute;vel mundial, o direito e autoriza&ccedil;&atilde;o para usar, copiar, editar, modificar, reproduzir, desenvolver obras derivadas, bem como para distribuir, divulgar, processar e utilizar de qualquer forma qualquer CONTE&Uacute;DO DO USU&Aacute;RIO, mediante an&uacute;ncios direcionados (no todo ou em parte), ou n&atilde;o, seja com rela&ccedil;&atilde;o ao <strong>ZECA TV</strong> ou qualquer dos SERVI&Ccedil;OS, seja com rela&ccedil;&atilde;o a qualquer neg&oacute;cio ou atividade do <strong>ZECA TV</strong>.</li>
                        <li>O USU&Aacute;RIO declara e garante que det&eacute;m todos os direitos e autoriza&ccedil;&otilde;es para fornecer, inserir, divulgar, publicar ou transmitir o &ldquo;CONTE&Uacute;DO DO USU&Aacute;RIO&rdquo;, bem como para conceder ao <strong>ZECA TV</strong> os direitos relativos ao &ldquo;CONTE&Uacute;DO DO USU&Aacute;RIO&rdquo; conforme acima, sem violar direitos de privacidade, intimidade, propriedade intelectual ou qualquer outro direito de terceiros.</li>
                        <li>N&atilde;o ser&aacute; permitido ao USU&Aacute;RIO:
                            <ol>
                                <li>Publicar, transmitir ou enviar informa&ccedil;&otilde;es de qualquer natureza que possam incitar, induzir, ou promover atitudes discriminat&oacute;rias, mensagens violentas ou delituosas que atentem contra aos bons costumes e &agrave; moral;</li>
                                <li>Usar endere&ccedil;os de computadores, de rede ou de correio eletr&ocirc;nico falso, bem como cadastrar-se com informa&ccedil;&otilde;es pessoais falsas ou de propriedade de terceiros;</li>
                                <li>Utilizar os SERVI&Ccedil;OS para fins diversos daqueles a que se destinam;</li>
                                <li>Enviar, transmitir, alterar, apagar e/ou corromper dados e informa&ccedil;&otilde;es de propriedade do <strong>ZECA TV</strong>, de outro USU&Aacute;RIO e/ou de terceiros, sem a devida autoriza&ccedil;&atilde;o;</li>
                                <li>Violar a privacidade de qualquer USU&Aacute;RIO ou terceiro ou, ainda, qualquer disposi&ccedil;&atilde;o da POL&Iacute;TICA DE PRIVACIDADE;</li>
                                <li>Enviar ou transmitir arquivos com v&iacute;rus de computador, com conte&uacute;do invasivo, destrutivo ou que cause dano tempor&aacute;rio e/ou permanente aos equipamentos do destinat&aacute;rio e/ou ao <strong>ZECA TV</strong>, podendo incorrer sob pena da lei;</li>
                                <li>Utilizar nomes e/ou apelidos considerados ofensivos, bem como os que contenham dados pessoais de qualquer outro USU&Aacute;RIO;</li>
                                <li>Utilizar qualquer arquivo, material, programa de computador ou c&oacute;digo que contenham qualquer v&iacute;rus, worms, malware e outros programas de computador que possam: (i) impedir, prejudicar ou de qualquer forma limitar o adequado funcionamento de equipamentos e softwares, o acesso &agrave; Internet e ao <strong>ZECA TV</strong> e/ou o uso dos SERVI&Ccedil;OS; (ii) implicar ou possibilitar o acesso n&atilde;o autorizado a informa&ccedil;&otilde;es confidenciais do <strong>ZECA TV</strong> e/ou dos demais USU&Aacute;RIOS, do <strong>ZECA TV</strong> ou dos SERVI&Ccedil;OS; (iii) causar danos a qualquer USU&Aacute;RIO e/ou ao <strong>ZECA TV</strong>;</li>
                                <li>Anexar URL&rsquo;s, endere&ccedil;os de e-mail ou qualquer outro material de cunho publicit&aacute;rio, inclusive de terceiros, sem pr&eacute;via autoriza&ccedil;&atilde;o do <strong>ZECA TV</strong>; e</li>
                                <li>Realizar propaganda direta de uma empresa ou organiza&ccedil;&atilde;o nas LIVES publicados no <strong>ZECA TV</strong>, sendo certo que toda publicidade dever&aacute; ser realizada na forma prevista nestes <strong>TERMOS DE USO</strong> para os fins exclusivos dos SERVI&Ccedil;OS, relacionados &agrave; publica&ccedil;&atilde;o e/ou visualiza&ccedil;&atilde;o das LIVES.</li>
                            </ol>
                        </li>
                        <li>O USU&Aacute;RIO reconhece e aceita que o <strong>ZECA TV</strong> poder&aacute;, a seu exclusivo crit&eacute;rio e sem aviso pr&eacute;vio:
                            <ol>
                                <li>Armazenar, gravar e/ou tirar &quot;print&quot; de qualquer CONTE&Uacute;DO DO USU&Aacute;RIO (incluindo di&aacute;logos e publica&ccedil;&otilde;es abertas);</li>
                                <li>Investigar qualquer alega&ccedil;&atilde;o de que um &ldquo;CONTE&Uacute;DO DO USU&Aacute;RIO&rdquo; n&atilde;o est&aacute; em conformidade com estes <strong>TERMOS DE USO</strong> ou com a POL&Iacute;TICA DE PRIVACIDADE e determinar a remo&ccedil;&atilde;o do CONTE&Uacute;DO DO USU&Aacute;RIO, ou ainda do pr&oacute;prio USU&Aacute;RIO;</li>
                                <li>Remover &ldquo;CONTE&Uacute;DO DO USU&Aacute;RIO&rdquo; que seja considerado abusivo, censur&aacute;vel, ilegal, perturbador ou que, de alguma forma, descumpra o previsto nestes <strong>TERMOS DE USO</strong>;</li>
                                <li>Editar qualquer CONTE&Uacute;DO DO USU&Aacute;RIO.</li>
                            </ol>
                        </li>
                        <li>Caso acredite que algum &ldquo;CONTE&Uacute;DO DO USU&Aacute;RIO&rdquo; viole algum direito de terceiros ou de qualquer forma descumpra o previsto nestes <strong>TERMOS DE USO</strong>, o USU&Aacute;RIO dever&aacute; contatar a <strong>BASCODE</strong> por meio do e-mail contato@zeca.tv. Ap&oacute;s receber comunica&ccedil;&atilde;o de um USU&Aacute;RIO, o <strong>ZECA TV</strong> notificar&aacute; o USU&Aacute;RIO respons&aacute;vel pelo CONTE&Uacute;DO DO USU&Aacute;RIO em quest&atilde;o para que este se manifeste no prazo de 72 (setenta e duas) horas. Caso seja verificada alguma viola&ccedil;&atilde;o ou descumprimento no CONTE&Uacute;DO DO USU&Aacute;RIO, o <strong>ZECA TV</strong> poder&aacute; excluir referido CONTE&Uacute;DO DO USU&Aacute;RIO, bem como avaliar&aacute; a ado&ccedil;&atilde;o de eventuais medidas c&iacute;veis e criminais cab&iacute;veis.</li>
                    </ol>
                </li>

                <li><strong>DA UTILIZA&Ccedil;&Atilde;O DA BASE DE DADOS:</strong>

                    <ol>
                        <li>O USU&Aacute;RIO expressamente aceita que a <strong>BASCODE</strong> e/ou qualquer de seus parceiros enviem mensagens de e-mail, whatsapp, SMS e quaisquer outros SERVI&Ccedil;OS correlacionados, de car&aacute;ter informativo, referentes &agrave;s comunica&ccedil;&otilde;es espec&iacute;ficas sobre os SERVI&Ccedil;OS disponibilizados ou que venham a ser disponibilizados na <strong>PLATAFORMA</strong>, bem como outras mensagens de natureza comercial, tais como ofertas dos parceiros e outras novidades desta.</li>
                        <li>Caso o <strong>USU&Aacute;RIO</strong> n&atilde;o deseje mais receber referidas mensagens dever&aacute; solicitar o cancelamento do seu envio na pr&oacute;pria <strong>PLATAFORMA</strong>, que ocorrer&aacute; de forma autom&aacute;tica.</li>
                        <li>O <strong>USU&Aacute;RIO</strong> poder&aacute; se descadastrar da <strong>PLATAFORMA</strong> sem qualquer &ocirc;nus. Quando isso ocorrer, este deixar&aacute; de receber automaticamente qualquer comunica&ccedil;&atilde;o da <strong>PLATAFORMA</strong>.
                            <ol>
                                <li>Ap&oacute;s o cancelamento do cadastro do <strong>USU&Aacute;RIO</strong>, seus dados n&atilde;o ser&atilde;o mais expostos aos demais USU&Aacute;RIOS, mas ser&atilde;o armazenados no banco de dados da PLATAFORMA, objetivando facilitar o recadastro. Acaso o <strong>USU&Aacute;RIO</strong> queira exercer o direito ao esquecimento, e retirar todos os seus dados do banco de dados da <strong>PLATAFORMA</strong>, este dever&aacute; solicitar no endere&ccedil;o contato@zeca.tv.</li>
                            </ol>
                        </li>
                        <li>N&atilde;o &eacute; permitida a utiliza&ccedil;&atilde;o de nenhum dispositivo, software, ou outro recurso que venha a interferir nas atividades e opera&ccedil;&otilde;es do APP, bem como nas publica&ccedil;&otilde;es de servi&ccedil;o, descri&ccedil;&otilde;es, contas ou seus bancos de dados. Qualquer intromiss&atilde;o, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibi&ccedil;&otilde;es estipuladas neste <strong>TERMOS DE USO</strong>, tornar&atilde;o o respons&aacute;vel pass&iacute;vel das a&ccedil;&otilde;es legais pertinentes, bem como das san&ccedil;&otilde;es aqui previstas, sendo ainda respons&aacute;vel pelas indeniza&ccedil;&otilde;es por eventuais danos causados.</li>
                    </ol>
                </li>

                <li><strong>DA PROPRIEDADE INTELECTUAL:</strong>

                    <ol>
                        <li>O <strong>USU&Aacute;RIO</strong> reconhece e aceita que os SERVI&Ccedil;OS e o <strong>ZECA TV</strong> constituem e/ou incorporam patentes, marcas, programas de computador, direitos autorias e demais propriedade industrial, segredos de empresa e/ou informa&ccedil;&otilde;es confidenciais que s&atilde;o e permanecer&atilde;o de titularidade exclusiva da <strong>BASCODE</strong>, demais PARCEIROS e/ou seus fornecedores, os quais s&atilde;o protegidos pela legisla&ccedil;&atilde;o local e pelos tratados internacionais relativos &agrave; propriedade intelectual (&quot;Propriedade Intelectual <strong>ZECA TV</strong>&quot;).</li>
                        <li>Sujeito &agrave;s disposi&ccedil;&otilde;es destes <strong>TERMOS DE USO</strong>, a <strong>BASCODE</strong> concede ao USU&Aacute;RIO devidamente cadastrado, em car&aacute;ter pessoal, intransfer&iacute;vel e n&atilde;o exclusivo, uma licen&ccedil;a para uso n&atilde;o comercial do <strong>ZECA TV</strong>, dos SERVI&Ccedil;OS e da Propriedade Intelectual <strong>ZECA TV</strong> ali disponibilizada. Estes <strong>TERMOS DE USO</strong> n&atilde;o contemplam nenhuma cess&atilde;o ou transfer&ecirc;ncia ao USU&Aacute;RIO da titularidade dos direitos relacionados a Propriedade Intelectual <strong>ZECA TV</strong>.</li>
                        <li>&Eacute; vedado ao USU&Aacute;RIO decifrar, decompilar, desmontar, fazer engenharia reversa ou de qualquer forma obter ou tentar obter os conceitos, o c&oacute;digo fonte ou os algoritmos utilizados em qualquer componente ou parte da Propriedade Intelectual <strong>ZECA TV</strong>, dos SERVI&Ccedil;OS ou do <strong>ZECA TV</strong>, quer diretamente, quer por interm&eacute;dio de terceiros, sob pena de responsabiliza&ccedil;&atilde;o civil e criminal.</li>
                    </ol>
                </li>

                <li><strong>DA LIMITA&Ccedil;&Atilde;O DE RESPONSABILIDADE:</strong>

                    <ol>
                        <li>O USU&Aacute;RIO ser&aacute; o &uacute;nico respons&aacute;vel, obrigando-se a isentar de responsabilidade e a indenizar a <strong>BASCODE</strong> com rela&ccedil;&atilde;o a qualquer reclama&ccedil;&atilde;o, a&ccedil;&atilde;o, perdas e/ou danos de qualquer forma relacionados a ou decorrentes de:

                            <ol>
                                <li>Qualquer descumprimento ou inobserv&acirc;ncia da legisla&ccedil;&atilde;o aplic&aacute;vel ou das disposi&ccedil;&otilde;es destes <strong>TERMOS DE USO</strong>;</li>
                                <li>Acesso ou utiliza&ccedil;&atilde;o do <strong>ZECA TV</strong> e/ou dos SERVI&Ccedil;OS pelo USU&Aacute;RIO, ou por terceiros a quem tenha possibilitado o acesso e uso de sua conta (ainda que n&atilde;o autorizado);</li>
                                <li>Qualquer CONTE&Uacute;DO DO USU&Aacute;RIO que vier a ser inserido no <strong>ZECA TV</strong> ou por interm&eacute;dio dos SERVI&Ccedil;OS.</li>
                            </ol>
                        </li>
                        <li>N&atilde;o obstante qualquer disposi&ccedil;&atilde;o em contr&aacute;rio contida nestes TERMOS DE USO, no <strong>ZECA TV</strong> ou em qualquer comunicado ou mensagem destinada ao USU&Aacute;RIO, em hip&oacute;tese alguma, a <strong>BASCODE</strong> ser&aacute; respons&aacute;vel, perante o USU&Aacute;RIO ou qualquer terceiro, por perda de dados ou informa&ccedil;&otilde;es, danos morais, lucros cessantes ou qualquer dano indireto.</li>
                        <li>Em fun&ccedil;&atilde;o da estrutura do <strong>ZECA TV</strong> e da pr&oacute;pria natureza dos SERVI&Ccedil;OS, a <strong>BASCODE</strong> n&atilde;o poder&aacute; garantir a identidade dos USU&Aacute;RIOS, a autenticidade ou exatid&atilde;o de qualquer conte&uacute;do, material ou informa&ccedil;&atilde;o que o USU&Aacute;RIO venha a acessar, receber ou de qualquer forma obter ou utilizar por interm&eacute;dio do <strong>ZECA TV</strong> ou dos SERVI&Ccedil;OS, n&atilde;o podendo, portanto, ser responsabilizada pelos referidos conte&uacute;dos, materiais ou informa&ccedil;&otilde;es de terceiros ou dos demais USU&Aacute;RIOS e tampouco pelo uso ou destina&ccedil;&atilde;o que o USU&Aacute;RIO vier a dar aos mesmos.</li>
                        <li>O USU&Aacute;RIO pode acessar certos links, aplicativos, conte&uacute;dos, SERVI&Ccedil;OS, promo&ccedil;&otilde;es, ofertas especiais ou outros eventos e atividades de terceiros (&quot;Aplicativos de Terceiros&quot;) atrav&eacute;s do <strong>ZECA TV</strong> e/ou dos SERVI&Ccedil;OS. Se o USU&Aacute;RIO optar por acessar os Aplicativos de Terceiros, poder&aacute; ser solicitado que fa&ccedil;a login e sincronize suas contas. O acesso e uso dos Aplicativos de Terceiros ser&aacute; realizado inteiramente por conta e risco do USU&Aacute;RIO. A <strong>BASCODE</strong> n&atilde;o tem controle sobre quaisquer Aplicativos de Terceiros acessados ou dispon&iacute;veis atrav&eacute;s do <strong>ZECA TV</strong> ou dos SERVI&Ccedil;OS e, portanto, n&atilde;o endossa, patrocina, recomenda ou aceita qualquer responsabilidade pelos Aplicativos de Terceiros. A <strong>BASCODE</strong> n&atilde;o &eacute; respons&aacute;vel por quaisquer perdas e danos causados ou supostamente causados por qualquer Aplicativo de Terceiros e recomenda aos USU&Aacute;RIOS que consultem a POL&Iacute;TICA DE PRIVACIDADE e os <strong>TERMOS DE USO</strong> do Aplicativo de Terceiros antes de utiliz&aacute;-lo.</li>
                    </ol>
                </li>

                <li><strong>DAS PENALIDADES</strong>

                    <ol>
                        <li>Caso o <strong>ZECA TV</strong> tome conhecimento de que o USU&Aacute;RIO (sejam <strong>ARTISTAS</strong> ou <strong>P&Uacute;BLICO</strong>) esteja violando as normas previstas nas exibi&ccedil;&otilde;es das LIVES, o <strong>ZECA TV</strong> ter&aacute; a liberdade de bloquear o acesso do mesmo &agrave; plataforma.</li>
                        <li>A <strong>ZECA TV</strong> poder&aacute; aplicar penalidades nos casos em que o USU&Aacute;RIO (sejam <strong>ARTISTAS</strong> ou <strong>P&Uacute;BLICO</strong>) cometer faltas, incluindo, exemplificativamente, as contidas na Cl&aacute;usula no 8.6.</li>
                        <li>Ap&oacute;s 3 (tr&ecirc;s) faltas, facultar&aacute; ao <strong>ZECA TV</strong> banir o USU&Aacute;RIO (sejam <strong>ARTISTAS</strong> ou <strong>P&Uacute;BLICO</strong>) do uso da <strong>PLATAFORMA</strong>.</li>
                        <li>No caso de alguma falta do ARTISTA descrita na cl&aacute;usula 12.2, este n&atilde;o far&aacute; jus a qualquer tipo de pagamento e estar&aacute; sujeito as penalidades previstas na cl&aacute;usula 12.3.</li>
                    </ol>
                </li>

                <li><strong>DAS PROMO&Ccedil;&Otilde;ES, DOS DESCONTOS E DOS VOUCHERS:</strong>

                    <ol>
                        <li>A <strong>BASCODE</strong> poder&aacute;, atrav&eacute;s de regulamento espec&iacute;fico de promo&ccedil;&otilde;es e/ou a&ccedil;&otilde;es de marketing, sozinha, ou em parceria com outras empresas e institui&ccedil;&otilde;es, e a seu livre e exclusivo crit&eacute;rio, oferecer vouchers e descontos ao USU&Aacute;RIO cadastrado na <strong>PLATAFORMA</strong>.

                            <ol>
                                <li>Os vouchers e descontos ser&atilde;o oferecidos e geridos pela pr&oacute;pria <strong>PLATAFORMA</strong>, n&atilde;o sendo de aceite obrigat&oacute;rio pelos USU&Aacute;RIOS (sejam <strong>ARTISTAS</strong> ou <strong>P&Uacute;BLICO</strong>).</li>
                            </ol>
                        </li>
                        <li>&Eacute; vedado aos USU&Aacute;RIOS (sejam <strong>ARTISTAS</strong> ou <strong>P&Uacute;BLICO</strong>) utilizarem promo&ccedil;&otilde;es e cupons de desconto ou vouchers em conluio com qualquer outro USU&Aacute;RIO ou com terceiros para obter vantagens indevidas ou fraudar o sistema de repasses da <strong>BASCODE</strong>.
                            <ol>
                                <li>Os USU&Aacute;RIOS que infringirem essa cl&aacute;usula poder&atilde;o ter seu contrato de licen&ccedil;a de uso de software suspenso ou resilido, al&eacute;m de negados seus repasses e pagamentos, sem preju&iacute;zo de apura&ccedil;&atilde;o de responsabilidade civil.</li>
                                <li>O eventual desconto ser&aacute; aplicado diretamente sobre o valor do SERVI&Ccedil;O indicado na <strong>PLATAFORMA</strong>.</li>
                            </ol>
                        </li>
                    </ol>
                </li>

                <li><strong>DA INEXIST&Ecirc;NCIA DE V&Iacute;NCULOS ENTRE A BASCODE E ARTISTAS</strong>

                    <ol>
                        <li>Os <strong>ARTISTAS</strong> reconhecem que estes <strong>TERMOS DE USO</strong> e/ou seu cadastro junto &agrave; <strong>PLATAFORMA</strong> n&atilde;o estabelecem qualquer tipo de v&iacute;nculo societ&aacute;rio, associativo, cooperativo ou trabalhista junto &agrave; <strong>BASCODE</strong>.</li>
                    </ol>
                </li>

                <li><strong>LEGISLA&Ccedil;&Atilde;O APLIC&Aacute;VEL E FORO DE ELEI&Ccedil;&Atilde;O:</strong>

                    <ol>
                        <li>Estes <strong>TERMOS DE USO</strong> s&atilde;o regidos pelas leis da Rep&uacute;blica Federativa do Brasil. As partes elegem desde j&aacute; o foro da Comarca da Capital do Estado do Rio de Janeiro para dirimir quaisquer d&uacute;vidas e/ou controv&eacute;rsias relacionadas ao acesso e uso do <strong>ZECA TV</strong>, dos SERVI&Ccedil;OS ou &agrave;s disposi&ccedil;&otilde;es destes <strong>TERMOS DE USO</strong>, com exclus&atilde;o de qualquer outro, por mais privilegiado que seja ou venha a ser.</li>
                    </ol>
                </li>

                <li><strong>DISPOSI&Ccedil;&Otilde;ES FINAIS:</strong>

                    <ol>
                        <li>A disponibiliza&ccedil;&atilde;o da <strong>PLATAFORMA</strong> tem dura&ccedil;&atilde;o indeterminada, podendo ser suspensa ou interrompida a qualquer momento, independentemente de comunicado ou avisado previamente por parte da <strong>BASCODE</strong>.</li>
                        <li>Os pais ou os representantes legais do menor de idade ou incapaz responder&atilde;o pelos atos por ele praticados na utiliza&ccedil;&atilde;o da <strong>PLATAFORMA</strong>, dentre os quais eventuais danos causados a terceiros, por pr&aacute;ticas de atos vedados pela lei e pelas disposi&ccedil;&otilde;es destes <strong>TERMOS DE USO</strong>.</li>
                        <li>A <strong>PLATAFORMA</strong> se reserva o direito de modificar, incluir e/ou alterar, a qualquer momento e sem necessidade de notifica&ccedil;&atilde;o pr&eacute;via, qualquer das disposi&ccedil;&otilde;es destes termos e condi&ccedil;&otilde;es de uso, pol&iacute;ticas e/ou instru&ccedil;&otilde;es, ao seu exclusivo crit&eacute;rio. Quaisquer mudan&ccedil;as, inclus&otilde;es e/ou altera&ccedil;&otilde;es entrar&atilde;o em vigor imediatamente ap&oacute;s sua divulga&ccedil;&atilde;o na <strong>PLATAFORMA</strong>, n&atilde;o havendo necessidade de aceite pr&eacute;vio por parte dos USU&Aacute;RIOS, respeitando-se a Cl&aacute;usula n&ordm;s 4.4 e 5.3.</li>
                        <li>A utiliza&ccedil;&atilde;o cont&iacute;nua da <strong>PLATAFORMA</strong> ap&oacute;s a divulga&ccedil;&atilde;o de eventuais mudan&ccedil;as, inclus&otilde;es e/ou altera&ccedil;&otilde;es, confirmar&aacute; a aceita&ccedil;&atilde;o destas pelo USU&Aacute;RIO. Portanto, o USU&Aacute;RIO se compromete, desde j&aacute;, a rever estes TERMOS E CONDI&Ccedil;&Otilde;ES DE USO e demais documentos aplic&aacute;veis, de tempos em tempos, de modo a verificar as disposi&ccedil;&otilde;es aplic&aacute;veis em vigor.</li>
                        <li>A <strong>BASCODE</strong> pode ceder este contrato a qualquer momento para qualquer matriz, subsidi&aacute;ria ou qualquer empresa afiliada, ou como parte da venda para fus&atilde;o, incorpora&ccedil;&atilde;o, com ou outra transfer&ecirc;ncia da empresa para uma outra entidade. Um aviso ser&aacute; colocado na <strong>PLATAFORMA</strong> sobre qualquer mudan&ccedil;a de propriedade para que o USU&Aacute;RIO tenha a oportunidade de interromper o uso da <strong>PLATAFORMA</strong> ou cancelar o seu registro.</li>
                        <li>Se eventual determina&ccedil;&atilde;o legal anular ou tornar ineficaz qualquer das disposi&ccedil;&otilde;es do presente termos e condi&ccedil;&otilde;es, permanecer&atilde;o v&aacute;lidas as suas demais condi&ccedil;&otilde;es, salvo caso o efeito da referida determina&ccedil;&atilde;o, a crit&eacute;rio do <strong>BASCODE</strong>, venha a afetar a viabilidade de manter a <strong>PLATAFORMA</strong> e dos SERVI&Ccedil;OS dispon&iacute;veis.</li>
                    </ol>
                </li>
            </ol>

            <p ></p>

            <br></br>

            <br></br>

            <br></br>

            <p><strong>BASCODE DESENVOLVIMENTO DE APLICATIVOS LTDA</strong>.</p>

            <p></p>

            <p>Nome Fantasia: <strong>ZECA TV</strong></p>

            <p>CNPJ n&ordm; 42.377.335/0001-73</p>

            <p>Rua MARIA CURUPAITI, 19 - Bairro: Vila Ester (Zona Norte), SAO PAULO - SP CEP 02452000. </p>

            <p>E-mail para: contato@zeca.tv.</p>

            <br></br>

            <p>Copyright (&copy;) 2023 .</p>

            <p>Todos os Direitos Reservados.</p>

        </div>

    );
}

export default PgaTermos;